
.header { 
	position: relative;
    color: $header-color;
    @include min(600px){
        .header__content {
            padding-right: 0;
        }
    }
    @include min(1024px){
        display: flex;
        .header__content {
            margin-right: 0;
            max-width: 1600px;
        }
    }

    &__container{
        position: relative;
        display: flex;
        width: 100%;
        background-color: $header-bg-color;
        order: 1;
        @include min(600px){
            @include pad($header-bg-color, left);
            order: 2;
        }
    }
    &__logo {
        display: block;
        margin: 0 auto 1.25em;
        font-size: 1rem;
        @include min(600px){
            margin: 0 0 1.25em;
        }
        @include min(800px){
            .-page-header &{
                display: inline-block;
                vertical-align: middle;
                margin: 0 2.5rem 0 0;
            }
        }
	}

	&__heading {
		color: $header-color;
		line-height: 1;
		padding-top: 20px;
		padding-bottom: 20px;
        white-space: nowrap;
        text-align: center;
        width: max-content;
		margin: {
			top: 0;
			bottom: 0;
		}
		font: {
			size: rem(70px);
			weight: 500;
        }
        .-page-header &{
            font-size: rem(50px);
        }
        @include min(600px){
            text-align: left;
        }
        &__container{
            position: relative;
            z-index: 20;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            @include min(600px){
                //width: 250px;
                width: auto;
                justify-content: flex-start;
                &:before{
                    position: absolute;
                    content: "";
                    top: 0px;
                    bottom: 0px;
                    width: 100%;
                    left: 50%;
                    background-color: $header-bg-color;
                    transform: skew(-15deg);
                    z-index: -1;
                }
            }
            @include min(1480px){
                //width: 350px;
            }
        }
        @include min(800px){
            &__inner_container{
                display: inline-block;
                vertical-align: middle;
            }
        }
	}

	&__subheading {
        margin-top: 1em;
        margin-bottom: .15em;
        display: block;
		font: {
            family: $font-body;
            weight: normal;
            size: rem(21px)
        }
        text-transform: uppercase;
        letter-spacing: 1.8px;
        @include min(600px){
            .-page-header &{
                margin-top: .75rem;
            }
        }
    }

	&__title {
		position: relative;
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
        z-index: 2;
        @include flex(df, fww, jcsb, aic);
    }
    
    &__aside{
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: $contact-bg-color;
        color: $contact-color;
        padding-top: 2em;
        @include min(600px){
            flex-direction: row;
            flex-wrap: nowrap;
            padding-left: 4%;
            padding-right: 4%;
        }
        @include min(1024px){
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;
        }
    }

	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}

	&__banner {
		text-align: center;
	}

	&__banner-image {
		max-width: 90%;
		margin-bottom: -17%;
	}

	&__banner-text {
		color: #ffffff;
		font-size: rem(25px);
		font-weight: 300;
		margin: 2.5em 0 0;
		font-family: $font-headings;
		text-shadow: 0 2px 15px rgba(#000000, .6);

		@include min(1200px) {
			font-size: rem(40px);
		}

		.massive {
			display: block;
			font-weight: bold;
			font-size: rem(55px);
			text-transform: uppercase;
			line-height: .75;

			@include min(1200px) {
				font-size: rem(90px);
			}
		}
    }
    @include bp("> 800px","<= 1280px"){
        &.-page-header .header__heading__container:before{
            width: 70%;
        }
    }
}
