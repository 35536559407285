
.contact {
    padding: 0.8em 1em 1em;
    box-sizing: border-box;
    font-style: normal;
    color: $contact-color;
    @include min(800px){
        width: 225px;
    }
    @include min(1280px){
        width: 270px;
    }

    &__heading{
        font-size: rem(30px);
        font-weight: 500;
        color: $contact-color;
    }

    @include link{
        color: $contact-color;
    }

    &__container{
        text-align: center;
        display: flex;
        flex-direction: column;
    }

	&__line {
		margin: 0.8 0 1em;

		&.-location,
		&.-phone,
		&.-email {
			padding-top: 0;
            padding-bottom: 0;
            line-height: 1.7;
            margin: 0 0 1em;
		}
    }
    &__more{
        margin-top: 3em;
    }
    @include link(".more__button") {
        color: $more-button-color;
        background-color: $more-button-bg-color;
        border-color: $more-button-border-color;
    }
}
