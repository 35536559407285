

.abo {
    &__form{
        .label{
            position: absolute !important;
            left: -9999rem !important;
            top: auto;
            outline: 0;
            width: 0;
            height: 0;
            overflow: hidden;
        }
        .input{
            background-color: $contact-form-input-bg-color;
            border-color: $contact-form-input-bg-color;
            color: $additional-color;
            border-radius: 1px;
            min-height: 55px;
        }

        .gdpr-agreement{
            margin: 0;
        }

        .form__button {
            border-radius: 1px;
            color: $contact-form-input-color;
            text-transform: uppercase;
            padding: 1em 2em;
            font-weight: bold;
            display: inline-block;
            font-size: rem(14px);
            border: solid 2px $contact-form-submit-border-color;
            min-width: 180px;
            cursor: pointer;
            transition: box-shadow 0.3s ease-in-out;
    
            &.-primary:not([disabled]) {
                color: $contact-form-submit-color;
                font-weight: bold;
                font-size: rem(17px);
                background-color: $contact-form-submit-bg-color;
                @include link-over(&){
                    box-shadow: 0 5px 20px $contact-form-input-color;
                }
            }
        }
    }
}