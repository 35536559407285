[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
    display: block;
    background: #dedede;
    width: 100%;
    min-width: 240px;
    padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}
 
body {
	color: #383433; 
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}

.main-wrapper {
	@include bp("> 800px") {  
		@include flex(df, fdrr);
	}
}

.main {
	padding-top: 2em;
    padding-bottom: 4em;
    @include bp("> 800px") {
        padding-left: 4vw;
        flex-grow: 1;
        .-nosidebar &{
            padding-left: 0;
        }
    }
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.home {
	display: flex;
	flex-direction: column;
}

.sidebar {
	padding-top: 2em;
	padding-bottom: 2em;
	min-width: 340px;
    position: relative;
    .-nosidebar &{
        display: none;
    }

	@include bp("> 800px") {
		width: ratio($max-width, 350px);
        flex-shrink: 0;
        border-right: solid #e8e7e7 10px;

		&::after {
			content: normal;
		}
	}
}

.content {
	max-width: 900px;
	margin-left: auto;
    margin-right: auto;
    .-nosidebar &{
        max-width: 100%;
    }
}

.section {
	padding: 10vw 0;

	.element-type-heading {
		font-size: rem(50px);
		text-transform: uppercase;
		color: #292A2D;
		text-align: center;
	}

	@include bp("> 800px") {
		padding: 5vw 0;
	}

	@include bp("> #{$max-width}") {
		padding: 4em 0;
	}
}

.centered {
    max-width: $max-width;
	width: 100%;
	margin-left: auto;
    margin-right: auto;
    
	&.-narrow {
        max-width: 1196px;
	}

	&.-padded {
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}


.section.-board{
    background: #ffffff;
    background-size: cover;
    @include min(600px){
        background: #000000 url(#{$img-loc}/v-202311221240/bg-board.jpg) no-repeat 50% 0 / 100% auto;
        background-size: cover;
    }
}

.more {
	text-align: center;

	&__button {
		padding: 1em 2em;
		font-weight: bold;
		display: inline-block;
        font-size: rem(14px);
        border: solid 2px;
        min-width: 180px;
        cursor: pointer;
        transition: box-shadow 0.3s ease-in-out;
	}
    &.board__more{
        .board__more-button{
            background-color: $board-more-bg-color;
            color: $board-more-color;
        }
    }
}

@include link(".more__button") {
	color: #1d1d1d;
	text-decoration: none;
}

@include link-over(".more__button") {
	box-shadow: 0 0 5px;
}

.text-component {
    margin-bottom: 2em;
    overflow: hidden; 

	p:last-child {
		margin-bottom: 0;
    }
        
    .image-align-left,
    .image-align-right{ 
        margin: 0;
        img{
            @include min(500px){
                max-width: 42.5%;
            }
        }
    }

    .image-align-left {
        img{
            float: left;
            margin: 0 1em .75em 0;
        }
        > *:first-of-type img{
            clear: both;
        }
    }

    .image-align-right{
        img{
            float: right;
            margin: 0 0 .75em 1em;
        }
        > *:first-of-type img{
            clear: both;
        }
    }

    .image-align-center{ 
        text-align: center;
        img{
            margin: 0 0 .75em;
            max-width: 100%;
        }
    }
    [contenteditable="true"] {
        .images-container,
        .image{
            &:focus, 
            &:hover{
                img{
                    box-shadow: 0 0 5px red;
                }        
            }
        } 
    }
}

.introduction {
	display: flex;
    flex-direction: column;
    &.section{
        padding-top: 0;
        padding-bottom: 2em;
    }

	@include bp("> 800px") {
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__flair {
		@include bp("> 800px") {
			width: ( 100% / 3 );
			padding-right: 3em;
			flex-shrink: 0;
		}
	}

	&__image {
        flex-grow: 0;
        min-width: 23%;
	}

	&__content {
		padding-top: 1em;
        padding-left: 4%;
        flex-grow: 1;
        @include min(600px){
            padding-top: 0;
        }
    }

    &__heading,
    h2{
		font-size: rem(44px);
		font-weight: 600;
	}

	&__text {
		font-size: rem(17px);
		line-height: ( 28 / 17 );
	}
}

.additional {
    background-color: $additional-bg-color;
	color: $additional-color;
	padding: 10vw 0 8vw;

	@include link {
		color: $additional-color;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}

	@include bp("> 800px") {
		padding: 5vw 0 3vw;
    }
    
	&__content {
		display: flex;
		flex-wrap: wrap;
	}

	&__section {
		min-width: 20em;
        margin-bottom: 2.5em;
        &.-all_rights{
            .additional__heading{
                font-weight: 500;
            }
        }

		@include min(75em) {
			flex-basis: 30%;
			margin-right: 5%;
		}

		&:last-child {
			margin-right: 0;
		}
    }
    &__heading,
    .element-type-heading{
        color: $additional-color;
        font-size: rem(44px);
    }
}

.all_rights{
    &__logo{
        display: block;
        
    margin-bottom: 0.5em;

    }
    &__subheading{
        margin-top: 0.5em;
        font-size: 1rem;
        display: block;
        font-weight: 300;
        font-family: $font-body;
    }
}

.attachments {
	margin: 0 0 3em;
	grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
	grid-gap: 3rem 1.5rem;
}
