
.search {
    position: relative;
    z-index: 3;
	@include flex(df, ais, fg0);
    padding: 0 0 0 1rem;
    margin-left: auto;
    order: 0;
    &-gte-container{
        z-index: 1;
        order: 0;
        padding: 1em 1em 1em;
        @include flex(df, fww, aic);
    }
    @include min(600px){
        &-gte-container{
            padding: 1.2em 2.5em 1em 1em;
        }
        &__form {
            flex-grow: 0;
        }
        &__input{
            width: 13em;
        }
    }
    @include min(800px){
        order: 1;
        &-gte-container{
            order: 1;
        }
        @include max(1680px){
            &-gte-container{
                flex-direction: column;
            }
        }
    }
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/

.fullscreen_search_anchor {
    display: flex;
    z-index: 5;
    white-space: nowrap;
    margin-top: 2px;
    &__text{
        padding-right: .5em;
    }
    svg{
        width: 23px;
        height: 24px;
        fill: $search-button-bg-color;
    }

    @include link(&) {
        color: $search-color;
        text-decoration: underline;
    }

    @include link-over(&) {
        text-decoration: none;
    }

    // svg {
    // 	transition: stroke .25s;
    // 	stroke: currentColor;
    // 	vertical-align: middle;
    // }

    // &:hover,
    // &:focus,
    // &:active {
    // 	svg {
    // 		stroke: $color-main;
    // 	}
    // }
}

@include min(1024px) {
    .fullscreen-search-anchor {
        position: static;
    }
}

.fullscreen-search {
    transition: background-color .1s ease-out;
    position: fixed;
    top: 0;
    left: -999em;
    z-index: 19000;
    width: .1px;
    height: .1px;
    overflow: hidden;
    //background: transparent;
    text-align: center;
    font-family: $font-headings;
    background: rgba($main-nav-button-bg, .9);

    &:target,
    &[data-is-shown="true"] {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: auto;
        height: auto;
        backdrop-filter: blur(8px) grayscale(100%);
    }

    form {
        display: block;
        margin: 0;
		padding: 0;
        font-size: rem(16px);
        width: 80%;
        max-width: 1300px;
        margin: 0 auto;
        padding: 10% 0 0 0;
        text-align: left;
        color: $main-nav-color;
        line-height: 1.4;

        @media screen and (min-height: 35em) {
            padding-top: 14%;
        }

        @media screen and (orientation: portrait) {
            padding-top: 33%;
        }
    }

    &__close {
        transition: background-color .15s ease-out;
        position: absolute;
        top: 0;
        right: 0;
        width: 72px;
        height: 72px;
        cursor: pointer;
        background: transparent encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#{$main-nav-color}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>') no-repeat 50% 50%;
    }

    @include link-over(".fullscreen-search__close") {
        background-color: $main-nav-color;
        background-image: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#2a2a2a" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>')
    }

    &__label {
        font-size: rem(18px);
        display: block;
        margin: 0 0 .9em 0;
        color: $main-nav-color;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__container {
        position: relative;
        width: 100%;
    }

    &__button {
        border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}
        appearance: none;
        border: 0;
        background: transparent;
        color: $main-nav-color;
        width: 2em;
        height: 2em;
        position: absolute;
        right: 0;
        top: 50%;
        margin: -1.2em 0 0 0;
        padding: 0;

        @include min(1100px) {
            width: 3.8em;
            height: 3.8em;
            margin: -2.2em 0 0 0;
        }

        @include min($max-width) {
            width: 5em;
            height: 5em;
            margin: -2.5em 0 0 0;
        }
    }

    &__input {
        border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}
        box-sizing: border-box;
        font-size: rem(24px);
        width: 100%!important;
        height: auto!important;
        font-family: $font-headings;
        background: transparent!important;
        color: $main-nav-color;
        outline: 0;
        font-weight: normal;
        padding: 0 1em 0!important;
        border: dotted hsla(0,0%,100%,.8)!important;
        border-width: 0 0 2px!important;
        text-indent: 0!important;

        &:focus {
            box-shadow: none !important;
        }

        &::placeholder {
            color: rgba($main-nav-color, .8);
        }

        @include min(600px) {
            font-size: 4vw;
        }

        @include min($max-width) {
            font-size: rem(80px);
        }
    }
}
